<template>
  <span>
    <v-row class="mx-auto">
      <v-col class="pb-0 pt-1" cols="6">
        <input-month 
          label="Competência de*"
          rules="required|min:7"
          :selectedMonth="data_de"
          @monthSelected="setDataDe"
        />
      </v-col>
      <v-col class="pb-0 pt-1" cols="6">
        <input-month 
          label="Competência até*"
          rules="required|min:7"
          :selectedMonth="data_ate"
          @monthSelected="setDataAte"
        />  
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col 
        class="pb-0 pt-1" 
        cols="2"
      >
        <v-checkbox 
          v-model="selecionar_todos_temas"
          label="Selecionar todos os temas"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col 
        class="pb-0 pt-1" 
        cols="2" 
        v-for="(label, value) in temas_validos" 
        :key="value"
      >
        <v-checkbox 
          v-model="temas_selecionados"
          :label="label"
          :value="value"
        />
      </v-col>
    </v-row>
  </span>
</template>

<script>

export default {

  name: 'Comercial',
  
  components: {
    InputMonth: () => import('@/components/general/InputMonth.vue'),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      data_de: '',
      data_ate: '',
      temas_validos: {
        FaturamentoParte1: 'Faturamento - parte 1',
        FaturamentoParte2: 'Faturamento - parte 2',
        FaturamentoParte3: 'Faturamento - parte 3',
        LigacaoComObrasParte1: 'Ligação com Obras - parte 1',
        LigacaoComObrasParte2: 'Ligação com Obras - parte 2',
        DanosEletricosParte1: 'Danos Elétricos - parte 1',
        DanosEletricosParte2: 'Danos Elétricos - parte 2',
        AfericaoDeMedidores: 'Aferição de Medidores',
        EstruturaDeAtendimento: 'Estrutura de Atendimento',
        RecuperacaoDeReceitaParte1: 'Recuperação de Receita - parte 1',
        RecuperacaoDeReceitaParte2: 'Recuperação de Receita - parte 2',
        RecuperacaoDeReceitaParte3: 'Recuperação de Receita - parte 3',
        AlteracaoCadastral: 'Alteração Cadastral',
        GeracaoDistribuida: 'Geração Distribuída',
      },
      selecionar_todos_temas: false,
      temas_selecionados: []
    }
  },

  methods: {
    
    setDataDe(data) {
      this.data_de = data;
      this.filtersUpdated();
    },
    
    setDataAte(data) {
      this.data_ate = data;
      this.filtersUpdated();
    },
    
    filtersUpdated() {
      this.$emit('filtersUpdated', {
        data_de: this.data_de,
        data_ate: this.data_ate,
        temas_selecionados: this.temas_selecionados
      });
    }
  },

  watch: {

    item(newVal) {
      Object.assign(this.$data, newVal.filtros);
    },

    temas_selecionados() {
      this.filtersUpdated();
    },

    selecionar_todos_temas() {
      this.temas_selecionados = this.selecionar_todos_temas 
        ? Object.keys(this.temas_validos) 
        : [];
    }
  }
}

</script>